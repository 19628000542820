/* eslint-disable react/prop-types */
import { withRouter } from "react-router-dom";
import * as S from "./estilos";


import ImgComoFazemos from "../../Images/ImgComoFazemos.jpg";
import ImgIcon1 from "../../Images/Icon1.png";
import ImgIcon2 from "../../Images/Icon2.png";
import ImgIcon3 from "../../Images/Icon3.png";

function ComoFazemos(props: any) {

  return (
    <>
      <S.ForaIcons>
        <div className="ItemIcon">
          <img src={ImgIcon1} />
          <span>+ 15 Anos</span>
          <p>Experiência</p>
        </div> 
        <div className="ItemIcon">
          <img src={ImgIcon2} />
          <span>+ 23 mil</span>
          <p>Emissões</p>
        </div> 
        <div className="ItemIcon">
          <img src={ImgIcon3} />
          <span>+ 3 mil</span>
          <p>Clientes garantidos</p>
        </div> 
      </S.ForaIcons>
      <S.ForaComoFazemos>
        <div className="EsqComoFazemos">
          <img src={ImgComoFazemos} />
        </div>
        <div className="DirComoFazemos">
          <h2>Como Fazemos isso?</h2>
          <p>
            A Federal Fianças e Crédito é especializada em oferecer serviços de fiança 
            ou garantia através de Cartas Fiança, atuando como uma fiadora entre o credor e o devedor.
          </p>
          <p>
            Assumimos o papel de fiadora para a sua empresa, nos comprometendo a pagar a dívida em caso de inadimplência por parte do devedor.
          </p>
          <p>
            Essa garantia oferecida pela Federal Fianças e Crédito proporciona maior confiança e segurança nas transações comerciais, 
            tanto para o credor quanto para o devedor, pois caso ocorra o não pagamento da dívida, é possível recorrer à 
            Federal Fianças e Crédito para receber o valor devido.
          </p>
        </div>
      </S.ForaComoFazemos>
    </>
  );
}

export default withRouter(ComoFazemos);
