import styled from 'styled-components';
import ImgBgBanner from "../../Images/BgBanner.jpg";

export const BgBanner = styled.section`
    background-image: url(${ImgBgBanner});
    background-size: cover;
    background-position: center;
    height: 39vw;
    display: flex;
    align-items: center;

    .LeftBanner {
        width: 36%;
        padding-left: 9%;
    }

    h2 {
        color: #161c3e;
        font-weight: bold;
        font-size: 3.5vw;
    }

    p {
        color: #161c3e;
        font-size: 1.3vw;

        span {
            font-weight: bold;
        }
    }

    button {
        background-color: rgba(12, 30, 68, 0.2);
        border: 1px solid #161c3e;
        width: 230px;
        height: 45px;
        border-radius: 5px;
        color: #161c3e;
        font-weight: bold;
        font-size: 19px;
    }


    @media (max-width: 900px) {
        .LeftBanner {
            width: 52%;
        }

        p {
            font-size: 2.3vw;
        }

        button {
            width: 50%;
            height: 5vw;
            font-size: 2.5vw;
        }
    }
    
`;