import styled from 'styled-components';
import ImgBgBanner from "../../Images/BgBanner.jpg";
// export const BackgroundHeader = styled.header`
//   background-color: ${({ theme }) => theme.color.black};
//   background-color: ${({ theme }) => theme.color.primary};
//   position: fixed;
//   width: 100%;
//   z-index: 2;
// `;

export const BgHeader = styled.header`
    background-color: #0a163c;

    img {
        width: 275px;
    }

    .ForaHeader {
        display: flex;
        justify-content: space-between;
        width: 90%;
        margin: 0 auto;
        align-items: center;
        padding-top: 20px;
        padding-bottom: 20px;

        a {
            font-weight: 500;
            font-size: 16px;
            cursor: pointer;
        }

        .ButtonCotacao {
            background-color: #d5a22b;
            color: #fff;
            border: 0px;
            font-size: 15px;
            font-weight: 500;
            width: 195px;
            border-radius: 3px;
            height: 40px;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .ForaTelefone {
            p {  
                color: #fff;
                font-weight: bold;
                font-size: 15px;
                margin: 0px;
                margin-bottom: 5px;
                text-align: center;
            }
        }
    }

    .ForaMenu {
        width: 760px;
        display: flex;
        justify-content: space-between;
    }

    @media (max-width: 1420px) {
        img {
            width: 195px;
        }

        .ForaHeader {
            a {
                font-size: 14px;
            }

            .ButtonCotacao {
                font-size: 13px;
                width: 175px;
                height: 40px;
            }
        }
        
        .ForaMenu {
            width: 650px;
        }
    }  

    @media (max-width: 1140px) {
        .ForaMenu {
            display: none;
        }
    }

    @media (max-width: 500px) {
        img {
            width: 125px;
        }

        .ForaHeader {
            .ButtonCotacao {
                font-size: 9px;
                width: 120px;
                height: 30px;
            }
        }
    }
`;

export const ForaButton = styled.div`
    display: none;
    flex-direction: column;
    height: 40px;
    justify-content: space-between;

    span {
        width: 57px;
        height: 6px;
        background-color: #d5a22b;
        border-radius: 2px;
    }

    @media (max-width: 1140px) {
        display: flex;
    }

    @media (max-width: 500px) {
        height: 35px;

        span {
            width: 50px;
            height: 5px;
        }
    }
`;



export const Input = styled.input`
    width: 380px;
    height: 70px;
    border-radius: 10px;
    border: 0px;
    margin-top: 20px;
    font: 20px "montserratlight", Arial, sans-serif;
    padding-left: 16px;
    background-color: #0a163c;
    color:#ffffff;
`;


export const Botao = styled.button`
    width: 260px;
    height: 40px;
    margin: 0 auto;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    background-color: #161c3e;
    border: 0px;
    color: #fff;
    font-weight: 900;
    text-transform: uppercase;
    border-radius: 6px;
    cursor: pointer;
    margin-top: 40px;
    margin-bottom: 35px;
`;

