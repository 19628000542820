/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import * as S from "./estilos";

import Load from "../Load";

function Banner(props: any) {

  return (
    <>
      <S.BgBanner>
        <div className="LeftBanner">
          <h2>Fornecemos garantias contratuais para o cumprimento de contratos firmado.</h2>
          <p>Somos especializados em <span>emissão de fianças</span>, com mais de <span>15 anos de experiência.</span></p>
          <button>Saiba como</button>
        </div>
      </S.BgBanner>
    </>
  );
}

export default withRouter(Banner);
