import styled from 'styled-components';

export const ForaCotacao = styled.section`

    h2 {
        font-weight: bold;
        color: #161c3e;
        text-transform: uppercase;
        text-align: center;
        padding-top: 40px;
        padding-bottom: 50px;
        font-size: 30px;
    }

    form {
        display: flex;
        flex-direction: column;
        width: 1000px;
        margin: 0 auto;
    }

    label {
        padding-top: 15px;
        padding-bottom: 8px;
        font-weight: bold;
        color: #161c3e;
    }

    input {
        height: 35px;
        border-radius: 8px;
        border: 2px solid #d5a22b;
        padding-left: 15px;
    }

    button {
        width: 260px;
        height: 40px;
        margin: 0 auto;  
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #161c3e;
        border: 0px;
        color: #fff;
        font-weight: 900;
        text-transform: uppercase;
        border-radius: 6px;
        cursor: pointer;
        margin-top: 40px;
        margin-bottom: 35px;
    }

    @media (max-width: 1100px) {
        form {
            width: 90%;
        }
    }

    @media (max-width: 700px) {
        h2 {
            padding-top: 25px;
            padding-bottom: 10px;
        }
    }
`;