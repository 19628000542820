import styled from 'styled-components';

export const ForaBeneficiario = styled.div`
    width: 1220px;
    margin: 0 auto;
    padding-top: 40px;
    padding-bottom: 60px;

    h2 {
        color: #161c3e;
        font-size: 32px;
        text-align: center;
        font-weight: bold;
    }

    .RiskBeneficiarios {
        height: 1px;
        width: 509px;
        background-color: #686868;
        margin: 0 auto;
        margin-top: 20px;
        margin-bottom: 50px;
    }

    .TextBeneficiario {
        font-weight: bold;
        text-align: center;
    }

    .ForaSlider {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    @media (max-width: 1270px) {
        width: 85%;

        h2 {
            font-size: 28px;
        }

        .RiskBeneficiarios {
            width: 100%;
        }
    }

    @media (max-width: 700px) {
        .ItemSlider {
            padding-top: 15px;
        }

        .RiskBeneficiarios {
            margin-bottom: 15px;
        }
    }
`;

export const ForaButton = styled.a`
    background-color: #d5a22b;
    width: 200px;
    color: #fff;
    font-weight: bold;
    text-transform: uppercase;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 39px;
    border-radius: 5px;
    cursor: pointer;
    margin-bottom: 45px;
`;

