/* eslint-disable react/prop-types */
import { withRouter } from "react-router-dom";
import * as S from "./estilos";

import ImgIconProdutos1 from "../../Images/IconProdutos1.jpg";
import ImgIconProdutos2 from "../../Images/IconProdutos2.jpg";
import ImgIconProdutos3 from "../../Images/IconProdutos3.jpg";
import ImgIconProdutos4 from "../../Images/IconProdutos4.jpg";
import ImgIconProdutos5 from "../../Images/IconProdutos5.jpg";
import ImgIconProdutos6 from "../../Images/IconProdutos6.jpg";

function ProdutosInterna(props: any) {

  return (
    <>
    <S.ForaProdutos>
      <h2>Nossos Produtos</h2>
      <div className="RiskProdutos"></div>
      <div className="ForaProdutos">
        <S.ItemProdutos>
          <img src={ImgIconProdutos1} />
          <h3>CARTA FIANÇA CONTRATUAL</h3>
          <p>
            Garante a indenização pelo prejuízo decorrente do inadimplemento das obrigações assumidas pelo contratado com o contratante. 
            Se aplica a contratos de construção, fornecimento ou prestação de ser, retenções de pagamento, adiantamentos de pagamentos, 
            garantia de pagamento, perfeito funcionamento, concessões ou participações.
          </p>
        </S.ItemProdutos>
        <S.ItemProdutos>
          <img src={ImgIconProdutos2} />
          <h3>CARTA FIANÇA JUDICIAL</h3>
          <p>
            Garante o pagamento até o valor correspondente aos depósitos em juízo que o Réu necessita realizar no trâmite de procedimentos judiciais. 
            A cobertura desta Carta Fiança somente terá efeito depois de transitada em julgado a decisão ou acordo judicial favorável ao Autor cujo valor 
            da condenação ou da quantia acordada não haja sido pago pelo Réu.
          </p>
        </S.ItemProdutos>
        <S.ItemProdutos>
          <img src={ImgIconProdutos3} />
          <h3>CARTA FIANÇA DE CRÉDITO</h3>
          <p>
            Garante os riscos creditícios, dívidas incobráveis, recebíveis vencidos, riscos comerciais 
            e políticos do Afiançado com o Beneficiário. É um produto que protege o Beneficiário do risco de inadimplência das vendas 
            a crédito de produtos ou serviços ao Afiançado, sejam domésticas, exportações ou mistas, tanto no Brasil como no exterior.
          </p>
        </S.ItemProdutos>
      </div>

      <div className="ForaProdutos">
        <S.ItemProdutos>
          <img src={ImgIconProdutos4} />
          <h3>CARTA FIANÇA LOCATÍCIA</h3>
          <p>
            Garante ao Beneficiário (locador/proprietário do imóvel) o recebimento dos débitos locatícios por 
            parte do Afiançado (locatário), que compreende os pagamentos dos alugueis até o valor limite fixado na 
            <span> Carta Fiança</span>, podendo ser contratadas as coberturas de danos causados ao imóvel, multa por rescisão 
            contratual, pinturas e os encargos legais como IPTU, condomínio, água, luz e gás, cujas obrigações estão 
            definidas na Lei do Inquilinato (Lei Nº 8.245/91).
          </p>
        </S.ItemProdutos>
        <S.ItemProdutos>
          <img src={ImgIconProdutos5} />
          <h3>CARTA FIANÇA ADUANEIRO</h3>
          <p>
            Garante ao Beneficiário até o valor da garantia fixada na Carta Fiança, o cumprimento das obrigações do 
            Afiançado no <span>Trânsito Aduaneiro</span> ou na <span>Admissão Temporária</span> em importações de caráter temporário vinculadas ao Termo de 
            Responsabilidade a que se refere o decreto n° 6.759 de 05 de Fevereiro de 2009.
          </p>
        </S.ItemProdutos>
        <S.ItemProdutos>
          <img src={ImgIconProdutos6} />
          <h3>GARANTIA DE EXECUÇÃO FISCAL</h3>
          <p>
            O seguro de Garantia de Execução Fiscal foi projetado para casos de processos judiciais como <span>garantia para o pagamento de 
            dívidas com a União.</span> Se sua empresa possui uma dívida ativa com o governos Federal (União) ou com os governos Estaduais ou Municipais, 
            ela pode ficar sujeita a uma execução fiscal.
          </p>
        </S.ItemProdutos>
      </div>

      <div className="ForaProdutos">
        <S.ItemProdutos>
          <img src={ImgIconProdutos4} />
          <h3>FIANÇA DO CONCORRENTE EM LICITAÇÕES (Bid Bond)</h3>
          <p>
            Tem por objetivo garantir obrigações de manutenção da proposta ofertada e assinatura do contrato do licitante vencedor do Certame (licitação).
          </p>
        </S.ItemProdutos>
        <S.ItemProdutos>
          <img src={ImgIconProdutos3} />
          <h3>FIANÇA PARA ADIAMENTO DE PAGAMENTOS</h3>
          <p>
            Garantir o(s) adiantamento(s) concedidos pelo Contratante na forma prevista no Contrato.
          </p>
        </S.ItemProdutos>
        <S.ItemProdutos>
          <img src={ImgIconProdutos4} />
          <h3>FIANÇA DO EXECUTANTE DE OBRA OU SERVIÇO</h3>
          <p>
            Esse tipo de Carta Fiança, tem por objetivo garantir obrigações contratuais de Execução; Prestação de Serviço ou Fornecimento, permitindo a contratação de coberturas adicionais, tais como: Obrigações Previdenciárias/ Trabalhistas; Multas, etc.
          </p>
        </S.ItemProdutos>
      </div>

      <div className="ForaProdutos">
        <S.ItemProdutos>
          <img src={ImgIconProdutos6} />
          <h3>FIANÇA PARA RETENÇÃO DE PAGAMENTOS</h3>
          <p>
            Essa Carta garante o inadimplemento do Contrato, em função da substituição por Carta Fiança das retenções de pagamentos previstas no contrato.
          </p>
        </S.ItemProdutos>
      </div>
    </S.ForaProdutos>
    </>
  );
}

export default withRouter(ProdutosInterna);
