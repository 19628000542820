import styled from 'styled-components';

export const ForaSobreNos = styled.div`
    width: 1000px;
    margin: 0 auto;
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;

    h2 {
        color: #161c3e;
        font-weight: 500;
        margin-top: 30px;
        margin-bottom: 15px;

        span {
            font-weight: bold;
        }
    }

    p {
        color: #6e6e6e;
    }

    @media (max-width: 1050px) {
        width: 90%;
    }

    @media (max-width: 440px) {
        img {
            width: 100%;
            height: auto;
        }
    }
`;

export const ForaItensSobreNos = styled.div`
    display: flex;
    width: 1000px;
    margin: 0 auto;
    justify-content: space-between;
    margin-top: 20px;
    margin-bottom: 50px;

    @media (max-width: 1050px) {
        width: 90%;
    }

    @media (max-width: 600px) {
        flex-wrap: wrap;
        margin-top: 0px;
        margin-bottom: 20px;
    }
`;

export const ItemSobreNos = styled.div`
    width: 48%;
    border: 1px solid #161c3e;
    border-radius: 12px;
    height: 235px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .RiscoSobreNos {
        width: 70%;
        margin: 0 auto;
        height: 1px;
        background-color: #161c3e;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    h2 {
        color: #161c3e;
        font-weight: bold;
        text-transform: uppercase;
        text-align: center;
        font-size: 34px;
    }

    p {
        color: #6e6e6e;
        text-align: center;
        width: 80%;
        margin: 0 auto;
    }

    @media (max-width: 1050px) {
        height: auto;

        h2 {
            padding-top: 25px;
        }

        p {
            padding-bottom: 25px;
        }
    }

    @media (max-width: 600px) {
        width: 100%;
        margin-top: 20px;
    }
`;
