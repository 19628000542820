import React from "react";
import { BrowserRouter, /* HashRouter, Router,*/ Route, Switch } from "react-router-dom";
import Home from "../Pages/Home";
import SobreNos from "../Pages/SobreNos";
import ProdutosPage from "../Pages/Produtos";
import CotacaoPage from "../Pages/Cotacao";
import AvisoPage from "../Pages/Aviso";
import { ThemeProvider } from "styled-components";
import theme from "../Styles/theme";

const Router = () => {
  return (
    <>
      <ThemeProvider theme={theme}>
        <BrowserRouter basename="/">
          <Switch>
            <Route path="/" exact component={Home} />
            <Route path="/SobreNos" exact component={SobreNos} />
            <Route path="/Produtos" exact component={ProdutosPage} />
            <Route path="/Cotacao" exact component={CotacaoPage} />
            <Route path="/Aviso" exact component={AvisoPage} />
          </Switch>
        </BrowserRouter>
      </ThemeProvider>
    </>
  );
};

export default Router;
