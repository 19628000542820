import styled from 'styled-components';

export const BgPerguntas = styled.section`
    background-color: #0c1e44;

    h2 {
        padding-top: 35px;
        padding-bottom: 34px;
        color: #fff;
        font-size: 33px;
        text-align: center;
    }

    .ForaPerguntas {
        width: 900px;
        margin: 0 auto;
        padding-bottom: 30px;
    }

    .DirPerguntas {
        padding-left: 35px;
    }

    @media (max-width: 950px) {
        .ForaPerguntas {
            width: 90%;
        }

        .DirPerguntas {
            padding-left: 15px;
        }
    }
`;

export const ItemPerguntas = styled.div`
    display: flex;

    img {
        height: 125px;
        width: 115px;
    }

    h3 {
        color: #d5a22b;
    }

    p {
        color: #fff;
    }

    @media (max-width: 500px) {
        flex-direction: column;
        align-items: center;
        text-align: center;
        margin-top: -16px;
    }
`;
