import styled from 'styled-components';

export const ForaProdutos = styled.section`
    h2 {
        text-align: center;
        color: #161c3e;
        font-size: 35px;
        padding-top: 45px;
    }

    .RiskProdutos {
        height: 1px;
        width: 700px;
        background-color: #161c3e;
        margin: 0 auto;
        margin-top: 25px;
    }

    .ForaProdutos {
        width: 1300px;
        display: flex;
        margin: 0 auto;
        justify-content: space-between;
        margin-top: 50px;
        margin-bottom: 40px;
    }

    @media (max-width: 1360px) {
        .ForaProdutos {
            width: 920px;
        }
    }
    
    @media (max-width: 960px) {
        .ForaProdutos {
            width: 90%;
        }
    }

    @media (max-width: 770px) {
        .RiskProdutos {
            width: 90%;
        }

        .ForaProdutos {
            flex-wrap: wrap;
        }
    }

    @media (max-width: 500px) {
        .ForaProdutos {
            margin-top: 20px;
            margin-bottom: 15px;
        }
    }
`;

export const ItemProdutos = styled.div`
    width: 30%;

    h3 {
        color: #161c3e;
        font-weight: bold;
    }

    p {
        color: #6e6e6e;

        span {
            font-weight: bold;
            color: #161c3e;
        }
    }

    @media (max-width: 770px) {
        width: 100%;
    }
`;
