import React, { useEffect, useState } from "react";

import { Header, CotacaoInterna, Beneficiarios, Footer } from "../../Componentes";
import "react-confirm-alert/src/react-confirm-alert.css";


function Cotacao(props: any) {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <link rel="stylesheet" href="fontes/stylesheet.css" />
      <Header />

      <CotacaoInterna />

      <Beneficiarios />
      
      <a href={"https://wa.me/551126135368"} target="_blank" rel="noreferrer" className="BtWhatsapp"></a> 
   
      <Footer />
    </>
  );
}

export default Cotacao;
