import styled from 'styled-components';

export const ForaIcons = styled.div`
    width: 610px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    margin-top: 60px;

    .ItemIcon {
        width: 33%;
        display: flex;
        flex-direction: column;
        align-items: center;

        span {
            font-weight: 900;
            font-size: 20px;
            padding-top: 10px;
        }

        p {
            font-weight: 500;
            margin-top: 3px;
            text-align: center;
        }
    }

    @media (max-width: 620px) {
        width: 90%;
        margin-top: 30px;

        .ItemIcon {
            img {
                width: 75px;
            }
        }
    }
`;

export const ForaComoFazemos = styled.div`
    width: 1220px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    padding-top: 35px;

    h2 {
        color: #161c3e;
        font-weight: bold;
        font-size: 34px;
    }

    p {
        color: #6e6e6e;
        font-size: 18px;
    }

    .DirComoFazemos {
        padding-left: 95px;
    }

    @media (max-width: 1220px) {
        width: 850px;

        .DirComoFazemos {
            padding-left: 10px;
        }
    }

    @media (max-width: 870px) {
        width: 90%;
        flex-direction: column;
        padding-top: 15px;

        h2 {
            font-size: 28px;
        }

        p {
            font-size: 16px;
        }

        .EsqComoFazemos {
            width: auto;
            margin-bottom: 15px;

            img {
                width: 200px;
            }
        }

        .DirComoFazemos {
            width: 100%;
        }
    }
`;
