import React, { useEffect, useState } from "react";
import * as S from "./estilos";
import useData from "../../Utils/useData";
import { Button, Modal, Box, Typography } from '@mui/material';
// import Logo from "../../Images/Logo.png";

import ImgLogo from "../../Images/logo.jpg";

function Footer() {

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [cPin, setCPin] = useState(''); // Renomeie as variáveis aqui
  const Consulta = () => {
  
    
    window.open('https://federalfianca.com.br/consultaget.php?c_pin='+cPin);


    // fetch("https://federalfianca.com.br/consultaget.php?c_pin=SF1235JHI20240527", {
    //   method: "GET",
    //   headers: myHeaders,
    //   // body: formData,
    //   redirect: "follow",
    // })
    //   .then((response) => response.text())
      
    //   .catch((error) => console.log("error", error));
    // fetch("https://www.federalfianca.com.br/consulta.php", {
    //   method: "POST",
    //   headers: myHeaders,
    //   body: JSON.stringify(raw),
    //   redirect: "follow"
    // })
    //   .then((response) => response.text())
    //   .then((result) => console.log(result))
    //   .catch((error) => console.error(error));
  };

  const handleOpen = () => setIsModalOpen(true);
  const handleClose = () => setIsModalOpen(false);
  const handleChange = (e: { target: { value: React.SetStateAction<string>; }; }) => {
    setCPin(e.target.value);
  };

  return (
    <>
      <S.BgFooter>
      <Modal
        open={isModalOpen}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-title" variant="h6" component="h2">
          Consulte a Autenticidade da Fiança
          </Typography>
          <Typography id="modal-description" sx={{ mt: 2 }}>
          <S.Input type="text" value={cPin}  onChange={handleChange}  placeholder="DIGITE SEU CÓDIGO AQUI" name="c_pin"></S.Input>
          </Typography>

          <S.Botao onClick={()=>Consulta()}>CONSULTAR</S.Botao>
         
        </Box>
      </Modal>
        <div className="ForaFooter">
          <img src={ImgLogo} />
          <div className="MenuFooter">
            <a href="/">HOME</a>
            <a href="SobreNos">SOBRE NÓS</a>
            <a href="Produtos">PRODUTOS</a>
            <a href="Aviso">AVISO DE SINISTRO</a>
            <a onClick={()=>handleOpen()}>CONSULTA DE CARTA FIANÇA</a>
          </div>
          <img src={ImgLogo} className="ButtonCotacaoResp" />
          <div className="ForaTelefone">
            <p>(011) 3181-3965</p>
            <a href="Cotacao" className="ButtonCotacao">Fazer cotação do produto</a>
          </div>  
        </div>
        <a target="_blank" rel="noreferrer" href="https://www.google.com.br/maps/place/Federal+Fianca/@-23.5666448,-46.6535,17z/data=!3m1!4b1!4m6!3m5!1s0x94ce59b89c1d47bd:0x847ec9a5b9382ff2!8m2!3d-23.5666497!4d-46.6509251!16s%2Fg%2F11fphhynxx?hl=pt-BR&entry=ttu" className="TextLocal">Av Paulista, 807 - 23 (Bela Vista - SP) - CEP: 01311-100</a>
      </S.BgFooter>
    </>
  );
}
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  // border: '2px solid #000',
  // boxShadow: 24,
  p: 4,
};


const digite = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  // border: '2px solid #000',
  // boxShadow: 24,
  p: 4,
};

export default Footer;
