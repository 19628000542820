import React, { useEffect, useState } from "react";

import { Header, Banner, ComoFazemos, Beneficiarios, Perguntas, Produtos, Valores, Footer } from "../../Componentes";
import "react-confirm-alert/src/react-confirm-alert.css";

const endpoint = process.env.REACT_APP_ENDPOINT;

function Home(props: any) {
  const [numero, setNumero] = useState();
  const [whats, setWhats] = useState();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${endpoint}/whatsapp.php`);
        const data = await response.json();
        setNumero(data[0].c_numero);
        setWhats(data[0].c_zap);
        
      } catch (error) {
        console.error("Erro ao buscar os dados da API:", error);
      }
    };
    fetchData();

    const hash = props.history.location.hash;
    if (hash && document.getElementById(hash.substr(1))) {
      // Check if there is a hash and if an element with that id exists
      document.getElementById(hash.substr(1))?.scrollIntoView({ behavior: "smooth" });
    }
    // }, []);
  }, [props.history.location.hash]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <link rel="stylesheet" href="fontes/stylesheet.css" />
      <Header />

      <Banner />

      <ComoFazemos />

      <Beneficiarios />

      <Perguntas />

      <Produtos />

      <Valores />
      
      <a href={"https://wa.me/551126135368"} target="_blank" rel="noreferrer" className="BtWhatsapp"></a> 

      <Footer />
    </>
  );
}

export default Home;
