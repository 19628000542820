/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import * as S from "./estilos";

import { Alert } from "../atoms";

import Load from "../Load";

function CotacaoInterna(props: any) {
  const [load, setLoad] = useState(false);
  const [nome, setNome] = useState("");
  const [email, setEmail] = useState("");
  const [celular, setCelular] = useState("");
  const [empresa, setEmpresa] = useState("");
  const [cnpj, setCnpj] = useState("");

  const clearFields = () => {
    setNome("");
    setCelular("");
    setEmail("");
    setEmpresa("");
    setCnpj("");
  };

  const onSubmit = () => {
    let validaMenssages = [];
    validaMenssages.push(<div style={{ marginBottom: "10px" }}>Atenção, segue abaixo campos obrigatórios:</div>);
    let valida = false;

    if (nome === "" || nome === undefined) {
      validaMenssages.push(<div key={"Nome"}>- Nome</div>);
      valida = true;
    }
    if (email === "" || email === undefined) {
      validaMenssages.push(<div key={"E-mail"}>- E-mail</div>);
      valida = true;
    } else {
      if (
        // eslint-disable-next-line no-useless-escape
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          email,
        )
      ) {
        null;
      } else {
        validaMenssages.push(<div key={"E-mail"}>- E-mail (inválido)</div>);
        valida = true;
      }
    }

    if (celular === "" || celular === undefined) {
      validaMenssages.push(<div key={"Telefone"}>- Telefone</div>);
      valida = true;
    }
    if (empresa === "" || empresa === undefined) {
      validaMenssages.push(<div key={"Empresa"}>- Empresa</div>);
      valida = true;
    }    
    if (cnpj === "" || cnpj === undefined) {
      validaMenssages.push(<div key={"Cnpj"}>- Cnpj</div>);
      valida = true;
    }
    if (valida) {
      Alert(<div>{validaMenssages}</div>);
      console.log("Valida");
      console.log(validaMenssages[0]);
    } else {
      setLoad(true);

      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      const raw = JSON.stringify({
        Nome: nome,
        Celular: celular,
        Email: email,
        Empresa: empresa,
        Cnpj: cnpj
      });


      validaMenssages = [];

      fetch("https://federalfianca.com.br/api/envia_contato.php", {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      })
        .then((response) => response.text())
        .then((response: any) => {
          // console.log(response);
          setLoad(false);
          Alert(
            <>
              <div>Formulário de contato enviado com sucesso!</div>
              <div>Em breve entraremos em contato.</div>
            </>,
          );
          clearFields();
          validaMenssages = [];
          // setNome('');
          // setEmail('');
          // setCelular('');
          // setMensagem(''); 
        })
        .catch((error) => console.log("error", error));
    }
  };

  return (
    <>
      {load == true && <Load status={true} />}

      <S.ForaCotacao>
        <h2>Fazer cotação do produto</h2>
        <form>
          <label>Nome</label>
          <input type='text' name='c_nome' id='c_nome' value={nome} onChange={(e) => setNome(e.target.value)} />
          <label>E-mail</label>
          <input type='email' name='c_email' id='c_email' value={email} onChange={(e) => setEmail(e.target.value)} />
          <label>Celular</label>
          <input type='text' name='c_celular' id='c_celular' value={celular} onChange={(e) => setCelular(e.target.value)} />
          <label>Empresa</label>
          <input type='text' name='c_empresa' id='c_empresa' value={empresa} onChange={(e) => setEmpresa(e.target.value)} />
          <label>Cnpj</label>
          <input type='text' name='c_cnpj' id='c_cnpj' value={cnpj} onChange={(e) => setCnpj(e.target.value)} />  
        </form>
        <button onClick={() => onSubmit()}>Receber Cotação Agora</button>
      </S.ForaCotacao>
    </>
  );
}

export default withRouter(CotacaoInterna);
