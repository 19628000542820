/* eslint-disable prefer-const */
import React, { useState, ChangeEvent } from "react";
import { withRouter } from "react-router-dom";
import * as S from "./estilos";
import { Alert } from "../atoms";
import Load from "../Load";

interface Props {
  history: any;
}

const AvisoInterna: React.FC<Props> = (props: Props) => {
  const [load, setLoad] = useState(false);
  const [nome, setNome] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [celular, setCelular] = useState<string>("");
  const [empresa, setEmpresa] = useState<string>("");
  const [cnpj, setCnpj] = useState<string>("");
  const [cargo, setCargo] = useState<string>("");
  const [files, setFiles] = useState<FileList | null>(null);

  const clearFields = () => {
    setNome("");
    setCelular("");
    setEmail("");
    setEmpresa("");
    setCnpj("");
    setCargo("");
    setFiles(null);
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
        setFiles(event.target.files);
    }
};


const onSubmit = async () => {
  // Validations
  let validaMessages: JSX.Element[] = [];
  validaMessages.push(
      <div style={{ marginBottom: "10px" }}>
          Atenção, segue abaixo campos obrigatórios:
      </div>
  );
  let isValid = false;

  if (!nome) {
      validaMessages.push(<div key={"Nome"}>- Nome</div>);
      isValid = true;
  }
  if (!email) {
      validaMessages.push(<div key={"E-mail"}>- E-mail</div>);
      isValid = true;
  } else {
      if (
          // eslint-disable-next-line no-useless-escape
          !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
              email
          )
      ) {
          validaMessages.push(<div key={"E-mail"}>- E-mail (inválido)</div>);
          isValid = true;
      }
  }

  if (!celular) {
      validaMessages.push(<div key={"Telefone"}>- Telefone</div>);
      isValid = true;
  }
  if (!empresa) {
      validaMessages.push(<div key={"Empresa"}>- Empresa</div>);
      isValid = true;
  }
  if (!cnpj) {
      validaMessages.push(<div key={"Cnpj"}>- Cnpj</div>);
      isValid = true;
  }
  if (!cargo) {
      validaMessages.push(<div key={"Cargo"}>- Cargo</div>);
      isValid = true;
  }

  if (isValid) {
      Alert(<div>{validaMessages}</div>);
  } else {
      setLoad(true);

      const formData = new FormData();
      if (files) {
          for (let i = 0; i < files.length; i++) {
              formData.append(`file_${i}`, files[i]);
          }
      }
      formData.append("Nome", nome);
      formData.append("Celular", celular);
      formData.append("Email", email);
      formData.append("Empresa", empresa);
      formData.append("Cnpj", cnpj);
      formData.append("Cargo", cargo);

      try {
          const response = await fetch(
              "https://federalfianca.com.br/api/envia_aviso.php",
              {
                  method: "POST",
                  body: formData,
              }
          );

          if (response.ok) {
              setLoad(false);
              Alert(
                  <>
                      <div>Formulário de contato enviado com sucesso!</div>
                      <div>Em breve entraremos em contato.</div>
                  </>
              );
              clearFields();
          } else {
              setLoad(false);
              console.error("Erro ao enviar o formulário");
          }
      } catch (error) {
          console.error("Erro ao enviar o formulário:", error);
      }
  }
};

  return (
    <>
      {load && <Load status={true} />}

      <S.ForaCotacao>
        <h2>Aviso de sinistro</h2>
        <form>
          <label>Nome</label>
          <input
            type="text"
            name="c_nome"
            id="c_nome"
            value={nome}
            onChange={(e) => setNome(e.target.value)}
          />
          <label>E-mail</label>
          <input
            type="email"
            name="c_email"
            id="c_email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <label>Celular</label>
          <input
            type="text"
            name="c_celular"
            id="c_celular"
            value={celular}
            onChange={(e) => setCelular(e.target.value)}
          />
          <label>Empresa</label>
          <input
            type="text"
            name="c_empresa"
            id="c_empresa"
            value={empresa}
            onChange={(e) => setEmpresa(e.target.value)}
          />
          <label>Cnpj</label>
          <input
            type="text"
            name="c_cnpj"
            id="c_cnpj"
            value={cnpj}
            onChange={(e) => setCnpj(e.target.value)}
          />
          <label>Cargo</label>
          <input
            type="text"
            name="c_cargo"
            id="c_cargo"
            value={cargo}
            onChange={(e) => setCargo(e.target.value)}
          />
          {/* 6 file inputs */}
          <label>Contrato principal + aditivos</label>
          <input
            type="file"
            className="InputFile"
            onChange={handleFileChange}
            required
          />
          <label>Cópia integral do processo administrativo(Troca de e-mails e etc)</label>
          <input
            type="file"
            className="InputFile"
            onChange={handleFileChange}
            required
          />
          <label>Notificações enviadas ao fornecedor (Afiançado)</label>
          <input
            type="file"
            className="InputFile"
            onChange={handleFileChange}
            required
          />
          <label>Respostas das notificações do afiançado (se tiver).</label>
          <input
            type="file"
            className="InputFile"
            onChange={handleFileChange}
            required
          />
          <label>Relatório de valores retidos do afiançado (se houver).</label>
          <input
            type="file"
            className="InputFile"
            onChange={handleFileChange}
            required
          />
          <label>Dados Bancarios para efetivação da indenização do pagamento do sinistro.</label>
          <input
            type="file"
            className="InputFile"
            onChange={handleFileChange}
            required
          />
        </form>
        <button onClick={onSubmit}>Receber Cotação Agora</button>
      </S.ForaCotacao>
    </>
  );
};

export default withRouter(AvisoInterna);
