import styled from 'styled-components';
// import BgRodape from "../../Images/BgRodape.jpg";

export const BgFooter = styled.footer`
  background-color: #0a163c;

  .ForaFooter {
    width: 90%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding-top: 30px;
    padding-bottom: 15px;
  }

  img {
    width: 270px;
  }
  
  .ButtonCotacao {
    background-color: #d5a22b;
    height: 40px;
    width: 210px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 7px;
    font-weight: bold;
    cursor: pointer;
  }

  .ButtonCotacaoResp {
    display: none;
  }

  .MenuFooter {
    width: 730px;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
  }

  .ForaTelefone {
    p {  
      color: #fff;
      font-weight: bold;
      font-size: 15px;
      margin: 0px;
      margin-bottom: 5px;
      text-align: center;
    }
  }

  .TextLocal {
    color: #fff;
    text-align: right;
    padding-bottom: 30px;
    width: 95%;
    display: block;
  }


  @media (max-width: 1420px) {
    img {
      display: none;
    }

    .ButtonCotacaoResp {
      display: block;
    }

    .MenuFooter {
      width: 100%;
      padding-bottom: 30px;
      flex-wrap: wrap;
    }
  }

  @media (max-width: 840px) {
    .MenuFooter {
      a {
        text-align: center;
        padding-top: 20px;
        width: 30%;
      }
    }
  }

  @media (max-width: 500px) {
    .MenuFooter {
      padding-bottom: 15px;

      a {
        padding-top: 10px;
        font-size: 13px;
        line-height: 20px;
      }
    }

    .ForaFooter {
      justify-content: center;
    }

    .ButtonCotacao {
      margin-top: 15px;
    }

    .ForaTelefone {
      p {  
        margin-top: 15px;
      }
    }
  }
`;



export const Botao = styled.button`
    width: 260px;
    height: 40px;
    margin: 0 auto;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    background-color: #161c3e;
    border: 0px;
    color: #fff;
    font-weight: 900;
    text-transform: uppercase;
    border-radius: 6px;
    cursor: pointer;
    margin-top: 40px;
    margin-bottom: 35px;
`;



export const Input = styled.input`
    width: 380px;
    height: 70px;
    border-radius: 10px;
    border: 0px;
    margin-top: 20px;
    font: 20px "montserratlight", Arial, sans-serif;
    padding-left: 16px;
    background-color: #0a163c;
    color:#FFFFFF
`;

