import styled from 'styled-components';

export const ForaValores = styled.section`
    display: flex;
    justify-content: space-between;
    width: 1300px;
    margin: 0 auto;
    padding-bottom: 30px;

    @media (max-width: 1360px) {
        width: 920px;
    }

    @media (max-width: 970px) {
       flex-wrap: wrap;
       width: 90%;
    }
`;

export const ItemValores = styled.div`
    width: 24%;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 255px;

    h2 {
        color: #161c3e;
        margin-top: 15px;
    }

    p {
        color: #6e6e6e;
        text-align: center;
    }

    @media (max-width: 1360px) {
        height: 310px;
    }

    @media (max-width: 970px) {
        width: 48%;
        height: auto;
    }
`;
