/* eslint-disable react/prop-types */
import { withRouter } from "react-router-dom";
import * as S from "./estilos";

import ImgIconValores1 from "../../Images/IconValores1.jpg";
import ImgIconValores2 from "../../Images/IconValores2.jpg";
import ImgIconValores3 from "../../Images/IconValores3.jpg";
import ImgIconValores4 from "../../Images/IconValores4.jpg";

function Valores(props: any) {

  return (
    <>
      <S.ForaValores>
        <S.ItemValores>
          <img src={ImgIconValores1} />
          <h2>EXCELÊNCIA</h2>
          <p>
            Operamos com altos níveis de excelência, o que se reflete em nosso compromisso com o cliente e 
            no constante aprimoramento de nossos produtos, serviços e procedimentos.
          </p>
        </S.ItemValores>
        <S.ItemValores>
          <img src={ImgIconValores2} />
          <h2>COMPROMISSO</h2>
          <p>
            Nosso compromisso é atuar de forma ética, profissional e transparente, respeitando todas as 
            legislações brasileiras vigentes a fim de oferecer segurança e tranquilidade 
            aos nossos clientes (afiançados e beneficiários).
          </p>
        </S.ItemValores>
        <S.ItemValores>
          <img src={ImgIconValores3} />
          <h2>INOVAÇÃO</h2>
          <p>
            Nosso fluxo operacional é realizado com tecnologia de ponta, monitorado por uma equipe qualificada e sempre atenta às constantes mudanças tecnológicas.
          </p>
        </S.ItemValores>
        <S.ItemValores>
          <img src={ImgIconValores4} />
          <h2>INTEGRIDADE</h2>
          <p>
            Nossos processos internos são monitorados de forma rigorosa pelo nosso departamento de compliance, 
            respeitando as diretrizes da lei de anticorrupção Lei nº 12.846, de 1º de agosto de 2013 e da LGPD, 
            Lei nº 13.709, de 14 de agosto de 2018
          </p>
        </S.ItemValores>
      </S.ForaValores>
    </>
  );
}

export default withRouter(Valores);
