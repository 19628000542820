/* eslint-disable react/prop-types */
import { withRouter } from "react-router-dom";
import * as S from "./estilos";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import ImgItemBeneficiario1 from "../../Images/ItemBeneficiario1.jpg";
import ImgItemBeneficiario2 from "../../Images/ItemBeneficiario2.jpg";
import ImgItemBeneficiario3 from "../../Images/ItemBeneficiario3.jpg";
import ImgItemBeneficiario4 from "../../Images/ItemBeneficiario4.jpg";
import ImgItemBeneficiario5 from "../../Images/ItemBeneficiario5.jpg";

function Beneficiarios(props: any) {

  const settings = {
    dots: true,
    infinite: true,
    speed: 100,
    autoplay: false,
    autoplaySpeed: 2000,
    slidesToShow: 4,
    slidesToScroll: 4,

    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
    ],
  };

  return (
    <>
      <S.ForaBeneficiario>
        <h2>Beneficiários que confiam no nosso trabalho</h2>
        <div className="RiskBeneficiarios"></div>
        <div className="ForaSlider">
            <div className="ItemSlider">
              <img src={ImgItemBeneficiario1} />
              <div className="TextBeneficiario">Marinha</div>
            </div>
            <div className="ItemSlider">
              <img src={ImgItemBeneficiario2} />
              <div className="TextBeneficiario">Exército</div>
            </div>
            <div className="ItemSlider">
              <img src={ImgItemBeneficiario3} />
              <div className="TextBeneficiario">Aeronáutica</div>
            </div> 
            <div className="ItemSlider">
              <img src={ImgItemBeneficiario4} />
              <div className="TextBeneficiario">Governo</div>
            </div> 
            <div className="ItemSlider">
              <img src={ImgItemBeneficiario5} />
              <div className="TextBeneficiario">Banco do Brasil</div>
            </div> 
        </div>
      </S.ForaBeneficiario>
      <S.ForaButton target="_blank" href='https://federalfianca.com.br/arquivos/RELAC%CC%A7A%CC%83O%20BENEFICIA%CC%81RIOS.pdf'>Ver mais</S.ForaButton>
    </>
  );
}

export default withRouter(Beneficiarios);
