/* eslint-disable react/prop-types */
import { withRouter } from "react-router-dom";
import * as S from "./estilos";

import ImgSobreNos from "../../Images/ImgSobreNos.jpg";


function SobreNosConteudo(props: any) {


  return (
    <>
      <S.ForaSobreNos>
        <img src={ImgSobreNos} />
        <h2>HÁ MAIS DE <span>15 ANOS</span> ESPECIALIZADOS EM EMISSÃO DE FIANÇAS</h2>
        <p>
          Nossa instituição está preparada para fornecer soluções confiáveis e eficientes para suas necessidades de 
          garantia contratuais, judiciais e financeiras com transparência e segurança jurídica, seja nas relações com empresas públicas ou privadas.
        </p>
        <p>
          Nossa empresa se destaca por oferecer um serviço personalizado e de qualidade, adaptado às demandas específicas de 
          cada cliente, compreendendo a importância das cartas de fiança como instrumentos de garantia nas transações comerciais e contratos. 
          Nossa missão é fornecer uma alternativa segura e confiável para empresas e indivíduos que precisam de garantias financeiras, permitindo o cumprimento de 
          obrigações contratuais e a mitigação de riscos. 
        </p>
        <p>
          Com uma equipe experiente e qualificada, estamos comprometidos em oferecer um serviço ágil e eficiente. 
          Nossos especialistas trabalham em estreita colaboração com cada cliente, entendendo suas necessidades específicas e fornecendo soluções 
          personalizadas que atendam aos requisitos de cada transação.
        </p>
      </S.ForaSobreNos>
      <S.ForaItensSobreNos>
        <S.ItemSobreNos>
          <h2>MISSÃO</h2>
          <div className="RiscoSobreNos" />
          <p>
            Oferecer as melhores soluções em Cartas Fianças, garantindo tranquilidade, excelência e 
            segurança aos clientes e parceiros, provendo serviços com preço justo, inovação e flexibilidade.
          </p>
        </S.ItemSobreNos>
        <S.ItemSobreNos>
          <h2>VISÃO</h2>
          <div className="RiscoSobreNos" />
          <p>
            Buscamos ser a principal referência em Afiançador no mercado com agilidade e excelência no atendimento aos 
            nossos parceiros, ampliando assim nossa área de atuação e atendendo cada vez mais e melhor. 
          </p>
        </S.ItemSobreNos>
      </S.ForaItensSobreNos>
    </>
  );
}

export default withRouter(SobreNosConteudo);
