/* eslint-disable react/prop-types */
import { withRouter } from "react-router-dom";
import * as S from "./estilos";

import ImgIconPerguntas1 from "../../Images/IconPerguntas1.jpg";
import ImgIconPerguntas2 from "../../Images/IconPerguntas2.jpg";
import ImgIconPerguntas3 from "../../Images/IconPerguntas3.jpg";

function Perguntas(props: any) {

  return (
    <>
      <S.BgPerguntas>
        <div className="ForaPerguntas">
          <h2>Perguntas Frequentes</h2>
          <div className="ForaItensPerguntas">
            <S.ItemPerguntas>
              <img src={ImgIconPerguntas1} />
              <div className="DirPerguntas">
                <h3>O que é uma carta fiança?</h3>
                <p>
                  Uma carta fiança é um instrumento utilizado para garantir o pagamento de contratos firmados, 
                  assegurando que a parte beneficiária receberá os valores devidos caso a parte devedora não cumpra com suas obrigações. 
                  Essa garantia tem previsão legal conforme o Art. 818 do Código Civil e a carta fiança é um documento que comprova essa garantia nos moldes do Art. 
                  819 do Código Civil.
                </p>
              </div>
            </S.ItemPerguntas>
            <S.ItemPerguntas>
              <img src={ImgIconPerguntas2} />
              <div className="DirPerguntas">
                <h3>Quem pode utilizar?</h3>
                <p>
                  Qualquer empresa ou instituição pública que precisa apresentar uma garantia para assegurar um prazo, 
                  contrato, valor ou processo Judicial (fiscal, trabalhista ou civil).
                </p>
              </div>
            </S.ItemPerguntas>
            <S.ItemPerguntas>
              <img src={ImgIconPerguntas3} />
              <div className="DirPerguntas">
                <h3>Quando Utilizar?</h3>
                <p>
                  Empresas ou instituições públicas podem utilizar a carta fiança quando os contratos firmados 
                  exigirem uma garantia de pagamento. Alguns exemplos de situações em que a carta fiança pode ser 
                  requerida incluem contratos de prestação de serviços, licitações públicas, contratos de empréstimo e 
                  financiamento, transações comerciais internacionais, concessões ou participações e procedimentos judiciais.
                </p>
              </div>
            </S.ItemPerguntas>
          </div>
        </div>
      </S.BgPerguntas>
    </>
  );
}

export default withRouter(Perguntas);
